<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="$router.push('/student/study-abroad/admin')">
            <v-icon>far fa-arrow-left</v-icon>
          </v-btn>
        </template>
        <span>Back to Student List</span>
      </v-tooltip>
      <v-toolbar-title>Study Abroad Application for {{ name }} ({{ bannerId }})</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            Status: {{ status }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item :disabled="status === 'Unsubmitted'" @click="setStatus('Returned')">
            <v-list-item-title>Return to Student</v-list-item-title>
          </v-list-item>
          <v-list-item :disabled="status === 'Cancelled' || status === 'Archived'" @click="setStatus('Cancelled')">
            <v-list-item-title>Cancel</v-list-item-title>
          </v-list-item>
          <v-list-item :disabled="status === 'Unsubmitted' || status === 'Cancelled' || status === 'Archived'" @click="setStatus('Archived')">
            <v-list-item-title>Archive</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-tabs v-model="tab">
      <v-tab>
        Application
        <v-icon right>fal fa-file-alt</v-icon>
      </v-tab>
      <v-tab>
        <v-badge :content="unreadMessageCount" :value="unreadMessageCount" overlap color="red">
          Messages
          <v-icon right>fal fa-envelope</v-icon>
        </v-badge>
      </v-tab>
      <v-tab v-if="isAdmin">
        Approvals
        <v-icon right>fal fa-check-circle</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row v-if="formData.length > 0">
              <template v-for="({ field, value }, index) in formData">
                <v-col v-if="field.substring(0, 11) !== 'html-block-'" :key="field" cols="12" md="6" lg="4">
                  <v-checkbox v-if="value === true || value === false" v-model="formData[index].value" :label="field" readonly hide-details></v-checkbox>
                  <v-text-field v-else-if="typeof (value) === 'object' && 'value' in value" :value="value.value" :label="field" readonly hide-details></v-text-field>
                  <v-text-field v-else-if="Array.isArray(value)" :value="value.join(', ')" :label="field" readonly hide-details></v-text-field>
                  <v-text-field v-else :value="value" :label="field" readonly hide-details></v-text-field>
                </v-col>
              </template>
            </v-row>
            <v-row v-else>
              <v-col sm="12" md="6">
                <v-text-field :value="app.name" label="Name" readonly hide-details></v-text-field>
              </v-col>
              <v-col sm="12" md="6">
                <v-text-field :value="app.bannerId" label="Banner ID" readonly hide-details></v-text-field>
              </v-col>
              <v-col sm="12" md="6">
                <v-select :value="app.majors" :items="majorOptions" label="Major(s)" multiple small-chips readonly hide-details></v-select>
              </v-col>
              <v-col sm="12" md="6">
                <v-text-field v-model="app.gradYear" label="Graduation Year" readonly hide-details></v-text-field>
              </v-col>
              <v-col sm="12" md="6">
                <v-text-field v-model="app.program" label="Approved Off-Campus Study Program" readonly hide-details></v-text-field>
              </v-col>
              <v-col sm="12" md="6">
                <v-select v-model="app.programTerm" :items="termOptions" :readonly="!isAdmin" label="Term of Program" hide-details @change="updateAppTerm"></v-select>
              </v-col>
              <v-col sm="12" md="6">
                <v-checkbox v-model="app.requiredForMajor" label="Program is required for my major" readonly hide-details></v-checkbox>
              </v-col>
              <v-col sm="12" md="6">
                <v-checkbox v-model="app.international" label="Program is international" readonly hide-details></v-checkbox>
                <v-btn v-if="app.scannedPassport" :href="'https://drive.google.com/open?id=' + app.scannedPassport" target="_blank">View Passport</v-btn>
                <div v-else>Has not yet uploaded passport.</div>
              </v-col>
              <v-col sm="12" md="6">
                <v-text-field v-model="app.email" label="Email while studying abroad" readonly hide-details></v-text-field>
              </v-col>
              <v-col sm="12" md="6">
                <v-text-field v-model="app.instagram" label="Instagram ID" readonly hide-details></v-text-field>
              </v-col>
              <v-col sm="12">
                <v-checkbox v-model="app.pell" label="Has Pell Grant" readonly hide-details></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="6">
                <h5>Emergency Contact{{ emContacts.length > 1 ? 's' : '' }}</h5>
                <v-list>
                  <v-list-item v-for="(contact, index) in emContacts" :key="'ec-' + index">
                    <v-list-item-content>
                      <template v-for="{ field, value } in contact">
                        <v-list-item-title v-if="field === 'Name'" :key="'ec-' + index + '-' + field">{{ value }}</v-list-item-title>
                        <v-list-item-subtitle v-else :key="'ec-' + index + '-st-' + field">{{ field }}: {{ value }}</v-list-item-subtitle>
                      </template>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col sm="12" md="6">
                <h5>Course{{ courses.length > 1 ? 's' : '' }}</h5>
                <v-list>
                  <v-list-item v-for="(course, index) in courses" :key="'course-' + index">
                    <v-list-item-content>
                      <v-list-item-title>{{ 'course' in course ? course.course : course.name }}</v-list-item-title>
                      <v-list-item-subtitle>Equivalent to: {{ course.equivalent || 'Not Specified' }}</v-list-item-subtitle>
                      <v-list-item-subtitle>Credits: {{ course.credits }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="isAdvisor && !course.approvedBy">
                      <v-tooltip bottom v-if="course.equivalent !== ''">
                        <template v-slot:activator="{ on }">
                          <v-btn icon @click="approveCourse(index)" v-on="on">
                            <v-icon>fal fa-thumbs-up</v-icon>
                          </v-btn>
                        </template>
                        <span>Approve Course</span>
                      </v-tooltip>
                      <v-dialog v-model="course.equivalentDialog" width="300">
                        <template v-slot:activator="{ on: dialogOn }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltipOn }">
                              <v-btn icon @click="editEquivalent(index)" v-on="{ ...tooltipOn, ...dialogOn }">
                                <v-icon>fal fa-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Edit Course Equivalent</span>
                          </v-tooltip>
                        </template>
                        <v-card>
                          <v-card-title>Edit Equivalent Course</v-card-title>
                          <v-card-text>
                            <div style="margin-bottom:.5em">Course being taken: {{ course.course }}</div>
                            <div style="margin-bottom:.5em">Credits: {{ course.credits }}</div>
                            <v-text-field v-model="course.newEquivalent" label="Course Equivalent"></v-text-field>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn text @click="course.dialog = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="updateEquivalent(index, course)">Save</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-list-item-action>
                    <v-list-item-action v-else>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon color="success" v-on="on">{{ course.approvedBy ? 'fas fa-thumbs-up' : 'fal fa-question' }}</v-icon>
                        </template>
                        <span>{{ course.approvedBy ? 'Course approved by ' + course.approvedBy + ' on ' + stringFormatDate(course.approvedDate) : 'Course has not been approved by the advisor' }}</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn text to="/student/study-abroad/admin">Back to List</v-btn>
            <v-btn v-if="status !== 'Pending'" disabled>Application has not been submitted</v-btn>
            <v-btn v-else-if="!approval.approved" @click="grantApproval()" color="primary">Grant Approval</v-btn>
            <v-btn v-else disabled>Approval already granted by {{ approval.approvedBy }} on {{ stringFormatDate(approval.approvedDate) }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-timeline v-if="messages.length > 0">
              <v-timeline-item v-for="(msg, index) in messages" :key="'message-' + index" :left="msg.username === app.username" :right="msg.username !== app.username" :icon="msg.username === app.username ? 'fal fa-user' : 'fal fa-eye' + (msg.visibleToStudent ? '' : '-slash') ">
                <span slot="opposite">{{ msg.name }} - {{ stringFormatDate(msg.date) }}</span>
                <v-card class="elevation-2">
                  <v-card-text>
                    <p v-if="!msg.visibleToStudent" style="font-style:italic">**Not Visible to Student**</p>
                    {{ msg.message }}
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
            <v-row>
              <v-col cols="12" md="8" offset-md="2" lg="6" offset-lg="3">
                <v-textarea v-model="message" label="Message" outlined hide-details></v-textarea>
                <v-checkbox v-model="visibleToStudent" label="Message should be visible to student (student will be notified)"></v-checkbox>
                <v-btn @click="sendMessage()" color="primary">{{ visibleToStudent ? 'Send Message' : 'Add Note' }}</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>This is where you can review the granted approvals from advisors and other departments on campus.</v-card-text>
          <v-list>
            <v-list-item v-for="(item, index) in approvals" :key="'approval-' + index">
              <v-list-item-content>
                <v-list-item-title>{{ item.department }}</v-list-item-title>
                <v-list-item-subtitle v-if="item.name">{{ item.name }}</v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="item.username">{{ item.username }}</v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="item.approvedBy">{{ item.approvedBy }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon :color="item.approved ? 'success' : 'info'">{{ item.approved ? 'fas fa-check-circle' : 'fas fa-question-circle' }}</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    </v-card>
</template>
<script>
import { stringFormatDate } from '../../../../helpers/formatters'
import { ref, reactive, computed, watch, onMounted, onBeforeUnmount } from '@vue/composition-api'

export default {
  setup (props, { root }) {
    const id = computed(() => root.$router.currentRoute.params.id)
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isAdmin = computed(() => 'Academic Affairs' in root.$store.state.roles)
    const tab = ref(0)
    const status = ref('')
    const name = ref('')
    const bannerId = ref('')
    const formData = ref([])
    const emContacts = ref([])
    const app = reactive({
      bannerId: '',
      username: '',
      name: '',
      majors: '',
      gradYear: '',
      program: '',
      programTerm: '',
      requiredForMajor: false,
      international: false,
      scannedPassport: false,
      advisor: '',
      email: '',
      instagram: '',
      insuranceCompany: '',
      pell: false,
      emergencyContact: [{
        name: '',
        relation: '',
        phone: '',
        email: ''
      }],
      offCampusStudyAgreement: '',
      standardsOfConductAgreement: '',
      courses: [{
        course: '',
        credits: 0,
        equivalent: '',
        approvedBy: '',
        approvedDate: '',
        equivalentDialog: false
      }],
      submittedDate: ''
    })
    const majorOptions = ref([])
    const termOptions = computed(() => {
      let arr = []
      let dt = new Date()
      if (dt.getMonth() < 4) {
        // Allow for the fall and summer of the current year
        arr.push('Summer ' + dt.getFullYear())
        arr.push('Fall ' + dt.getFullYear())
      } else if (dt.getMonth() < 10) {
        // Allow for the spring of next year and following
        dt.setFullYear(dt.getFullYear() + 1)
        arr.push('Spring ' + dt.getFullYear())
        arr.push('Summer ' + dt.getFullYear())
        arr.push('Fall ' + dt.getFullYear())
      } else {
        // Allow for summer of next year and following
        dt.setFullYear(dt.getFullYear() + 1)
        arr.push('Summer ' + dt.getFullYear())
        arr.push('Fall ' + dt.getFullYear())
      }
      // Add one additional year's worth of term options
      dt.setFullYear(dt.getFullYear() + 1)
      arr.push('Spring ' + dt.getFullYear())
      arr.push('Summer ' + dt.getFullYear())
      arr.push('Fall ' + dt.getFullYear())
      if (app.programTerm !== '' && app.programTerm != null) {
        let exists = false
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] === app.programTerm) exists = true
        }
        if (!exists) arr.push(app.programTerm)
      }
      return arr
    })
    const courses = ref([])
    const approvalIndex = ref(null)
    const approval = ref({
      department: '',
      approved: false,
      name: '',
      username: '',
      approvedBy: '',
      approvedDate: ''
    })
    const messages = ref([])
    const approvals = ref([])
    const unreadMessageCount = ref(0)
    const unreadMessageUpdate = ref({})
    const service = root.$feathers.service('student/study-abroad')

    async function load (data) {
      const { username } = user.value
      const { roles } = user.value
      if (roles.filter((role) => role === 'Health & Counseling Services').length > 0) {
        switch (username) {
          case 'kim.ball':
          case 'kendra.keith':
          case 'aaron.reed':
            roles.push('Health Services')
            break
          default:
            roles.push('Priesthill Counseling Services')
        }
      }
      let doUpdate = false
      unreadMessageCount.value = 0
      approvals.value = data.approvals
      for (let i = 0; i < data.approvals.length; i++) {
        const { department } = data.approvals[i]
        // Filter the array of roles to any that match this department; if the length is > 0 then it exists and they have the role
        const hasRole = roles.filter((role) => role === department).length > 0
        if (hasRole || data.approvals[i].username === username) {
          approvalIndex.value = i
          let obj = Object.assign({}, data.approvals[i])
          delete obj.messages
          approval.value = obj
          messages.value = []
          if ('messages' in data.approvals[i] && Array.isArray(data.approvals[i].messages)) {
            for (let j = 0; j < data.approvals[i].messages.length; j++) {
              let isRead = false
              if ('readUsername' in data.approvals[i].messages[j]) {
                for (let k = 0; k < data.approvals[i].messages[j].readUsername.length; k++) {
                  if (data.approvals[i].messages[j].readUsername[k] === username) isRead = true
                }
              } else {
                data.approvals[i].messages[j].readUsername = []
              }
              if (!isRead) {
                unreadMessageCount.value++
                data.approvals[i].messages[j].readUsername.push(username)
                unreadMessageUpdate.value['approvals.' + i + '.messages.' + j] = data.approvals[i].messages[j]
                doUpdate = true
              }
            }
          }
          messages.value = data.approvals[i].messages
        }
      }
      if (approvalIndex.value == null && !isAdmin.value) {
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'You do not have permission to view this Study Abroad application.' })
        root.$router.push('/student/study-abroad/admin')
      }
      courses.value = []
      for (const course of data.courses) {
        courses.value.push({ ...course, equivalentDialog: false })
      }
      if ('formSubmission' in data) {
        const { dataArr } = await root.$feathers.service('forms/submission').get(data.formSubmission)
        if (dataArr) formData.value = dataArr.filter(({ field, value }) => field !== 'New Field' && (!Array.isArray(value) || value.length === 0 || typeof (value[0]) !== 'object'))
        else formData.value = []
        const temp = dataArr.filter(({ field }) => field === 'Emergency Contacts')
        emContacts.value = []
        if (temp.length > 0) {
          for (const contact of temp[0].value) {
            const arr = []
            for (const field in contact) {
              arr.push({ field, value: contact[field] })
            }
            emContacts.value.push(arr)
          }
        }
      }
      for (let l in app) {
        app[l] = data[l]
      }
      name.value = data.name
      bannerId.value = data.bannerId
      if (tab.value === 1 && doUpdate) {
        service.patch(id.value, unreadMessageUpdate.value)
      }
      if ('status' in data) {
        status.value = data.status
      } else {
        status.value = data.submitted ? 'Pending' : 'Unsubmitted'
        let approved = true
        for (let i = 0; i < approvals.value.length; i++) {
          if (!approvals.value[i].approved) {
            approved = false
            break
          }
        }
        if (approved) {
          status.value = 'Approved'
        }
        service.patch(id.value, { status: status.value })
      }
    }

    watch(id, () => {
      service.get(id.value).then((data) => {
        load(data)
      })
    })

    watch(tab, () => {
      if (tab.value === 1 && unreadMessageCount.value > 0) {
        service.patch(id.value, unreadMessageUpdate.value)
      }
    })

    const onPatchedListener = (data) => { if (data._id === props.id) load(data) }

    onMounted(() => {
      root.$feathers.service('forms/select-options').find({ query: { name: 'Major Code' } }).then(({ data }) => {
        root.$feathers.service('forms/select-options').get(data[0].value).then(({ options }) => { majorOptions.value = options })
      })
      service.on('patched', onPatchedListener)
      if (root.$router.currentRoute.hash === '#messages') {
        tab.value = 1
      }
    })

    onBeforeUnmount(() => { service.removeListener('patched', onPatchedListener) })

    const isAdvisor = computed(() => {
      if (user.value.username === app.advisor) return true
      return (approval.value.department === 'Advisor' || approval.value.department === 'Records')
    })

    function approveCourse (index) {
      let obj = {}
      obj['courses.' + index + '.approvedBy'] = user.value.username
      obj['courses.' + index + '.approvedDate'] = new Date()
      service.patch(id.value, obj)
    }

    function editEquivalent (index) {
      courses.value[index].newEquivalent = courses.value[index].equivalent
    }

    const sendMessageDialog = ref(false)
    const message = ref('')
    const visibleToStudent = ref(true)
    function sendMessage () {
      const { username, preferred, last } = user.value
      const name = preferred + ' ' + last
      let $push = {}
      $push['approvals.' + approvalIndex.value + '.messages'] = {
        username,
        name,
        date: new Date(),
        message: message.value,
        visibleToStudent: visibleToStudent.value,
        readUsername: [ username ]
      }
      service.patch(id.value, { $push })
      if (visibleToStudent.value) {
        // Send an email to the student letting them know they have a message
        root.$feathers.service('system/email').create({
          from: 'report-email@covenant.edu',
          to: app.email,
          subject: 'New Study Abroad Application Message',
          html: 'A new message was added to your study abroad application. Please <a href="https://portal.covenant.edu/student/study-abroad">log into your application</a> to view and respond to this message.',
          text: 'A new message was added to your study abroad application. Please log into your application (https://portal.covenant.edu/student/study-abroad) to view and respond to this message.'
        })
      }
      sendMessageDialog.value = false
      message.value = ''
    }

    function grantApproval () {
      let obj = {}
      obj['approvals.' + approvalIndex.value + '.approved'] = true
      obj['approvals.' + approvalIndex.value + '.approvedBy'] = user.value.username
      obj['approvals.' + approvalIndex.value + '.approvedDate'] = new Date()
      service.patch(id.value, obj).catch((e) => {
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'Error granting approval: ' + e })
      }).then(() => {
        root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Approval has been granted for the study abroad application for ' + app.name + '.' })
        root.$router.push('/student/study-abroad/admin')
      })
    }

    function updateEquivalent (index, course) {
      let obj = {}
      obj['courses.' + index + '.equivalent'] = course.newEquivalent
      service.patch(id.value, obj).catch((e) => {
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'Error updating course equivalent: ' + e })
      }).then(() => {
        root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Course equivalent has been updated.' })
      })
      courses.value[index].equivalentDialog = false
    }

    function updateAppTerm () {
      service.patch(id.value, { programTerm: app.programTerm }).catch((e) => {
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'Error updating term: ' + e })
      }).then(() => {
        root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Term has been updated.' })
      })
    }

    function setStatus (newStatus) {
      service.patch(id.value, { status: newStatus })
      status.value = newStatus
    }

    return {
      id,
      tab,
      majorOptions,
      termOptions,
      status,
      name,
      bannerId,
      formData,
      emContacts,
      app,
      courses,
      approvalIndex,
      approval,
      messages,
      approvals,
      unreadMessageCount,
      isAdvisor,
      isAdmin,
      approveCourse,
      editEquivalent,
      stringFormatDate,
      sendMessageDialog,
      message,
      visibleToStudent,
      sendMessage,
      grantApproval,
      updateEquivalent,
      updateAppTerm,
      setStatus
    }
  }
}
</script>
